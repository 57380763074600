$(document).ready(function(){
    var confirmActionButton;
    var confirmModal = $('#confirm-modal');

    confirmModal.on('show.bs.modal', function (event) {
        confirmActionButton = $(event.relatedTarget);

        confirmModal.find('.modal-title').html(confirmActionButton.data('message'));
        confirmModal.find('.loading').hide();
        confirmModal.find('button').show();
    });

    confirmModal.find('button').click(function(e){
        confirmModal.find('button').hide();
        confirmModal.find('.loading').fadeIn();

        var eventSuffix = confirmActionButton.data('event');

        confirmModal.trigger('confirm.' + eventSuffix, confirmActionButton.data());
    });

    confirmModal.on('confirm.vist-url', function(event, button){
        window.location = button.url;
    });
});
